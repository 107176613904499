import React from 'react';
import {Swiper} from 'swiper/react';
import {EffectFade, Autoplay, Pagination} from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

export default function SwiperBanner({children}) {
  return (
    <Swiper
      autoplay={{
        delay: 3000,
      }}
      pagination
      fadeEffect={{
        crossFade: true,
      }}
      spaceBetween={0}
      slidesPerView={1}
      modules={[EffectFade, Autoplay, Pagination]}
      effect='fade'
    >
      {children}
    </Swiper>
  );
}
