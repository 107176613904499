import React from 'react';
import {Row, Col} from 'antd';

import {utilities} from '~constants/home';

import * as styles from './styles.module.css';

const Utilities = () => {
  return (
    <Row gutter={[16, 16]} justify='space-between'>
      {utilities?.map(item => (
        <Col key={item.key} xs={24} md={12} lg={6}>
          <div className={styles.item}>
            <Row align='middle'>
              <Col span={6}>
                <div>{item.icon}</div>
              </Col>
              <Col className='content' span={18}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.content}>{item.content}</div>
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Utilities;
