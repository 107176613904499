import React from 'react';

const SquareLogo = ({fill = '#FCBAA4', size = '24'}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    version='1.1'
    viewBox='0 0 300 300'
    width={size}
    height={size}
    fill={fill}
  >
    <path
      d='M0.5 0.5H220C263.907 0.5 299.5 36.0934 299.5 80V299.5H80C36.0934 299.5 0.5 263.907 0.5 220V0.5Z'
      fill='none'
      stroke={fill}
    />
    <path
      d='M235.897 222.192H234.513C222.45 222.192 218.693 218.295 202.279 180.2C191.224 155.813 182.629 145.771 167.789 145.669C170.198 144.791 172.573 143.894 174.985 143.034H174.969C191.959 137.396 206.363 126.034 206.363 110.498C206.363 87.5545 190.203 75 157.114 75C154.992 75 146.801 75.2741 140.568 75.4665C137.1 75.5698 134.223 75.65 133.25 75.65C130.284 75.65 116.836 75 111.299 75C109.717 75 109.717 76.9467 111.299 76.9467H114.858C124.549 76.9467 125.537 77.8129 125.537 131.059V145.836C125.548 145.931 125.515 148.586 125.462 152.684C120.85 153.256 98.0273 156.83 84.0481 163.158C69.124 169.914 61.875 179.41 61.875 193.011L74.3373 185.979C74.8547 167.368 100.955 158.871 125.435 154.668C125.265 167.3 124.949 189.932 124.949 198.318C124.949 211.271 118.454 223.047 112.045 223.053H111.895C110.313 223.053 110.313 224.999 111.895 224.999C113.477 224.999 130.682 224.568 133.648 224.568C136.418 224.568 149.667 224.999 155.204 224.999C156.785 224.999 156.785 223.053 155.204 223.053H151.249C141.558 223.053 140.57 222.187 140.57 168.94V152.385C145.454 151.636 150.611 150.784 153.62 150.116C153.964 150.04 154.317 150.018 154.668 150.052C169.101 151.479 174.35 151.056 188.636 187.122C195.36 204.438 200.896 214.611 205.05 219.806C208.214 223.699 210.785 225 215.135 225C218.102 225 221.859 224.134 228.781 224.134H235.899C237.875 224.139 237.677 222.192 235.897 222.192ZM154.569 147.386C149.963 148.642 145.288 149.631 140.568 150.35V109.636C140.568 77.8161 140.952 76.9522 148.07 76.9522H155.574C179.621 76.9522 189.241 87.9916 189.241 110.502C189.241 121.869 185.415 130.054 179.836 135.563C176.061 138.607 171.882 141.112 167.417 143.004C163.257 144.803 158.961 146.269 154.569 147.386Z'
      fill={fill}
    />
  </svg>
);

export default SquareLogo;
