import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import {Row, Col, Skeleton} from 'antd';
import RichTextEditor from 'react-rte';
import SquareLogo from '~components/icons/square-logo';
import ArrowRight from '~components/icons/arrow-right';
import {get} from '~utils/api';

const ShortIntroduce = () => {
  const [loading, setLoading] = useState(true);
  const [pageDetail, setPageDetail] = useState(null);
  const [contentValue, setContent] = useState(null);

  useEffect(() => {
    setLoading(true);

    const getPageBySlug = async () => {
      try {
        setPageDetail(null);
        const res = await get(`/v1/page/slug/gioi-thieu-rentzy?status=PAGE_STATUS_PUBLISHED&type=PAGE_TYPE_ABOUT`);
        if (!res) return;
        setPageDetail(res);
        setContent(RichTextEditor.createValueFromString(res.content, 'markdown'));
      } finally {
        setLoading(false);
      }
    };
    Promise.all([getPageBySlug()]).finally(() => {
      setLoading(false);
    });
  }, []);

  if (loading) return <Skeleton />;
  return (
    <div>
      {pageDetail && (
        <Row className='introduce'>
          <Col className='logo' xs={0} md={9} lg={9}>
            <div className='icon'>
              <SquareLogo size='300' />
            </div>
          </Col>
          <Col xs={24} md={13} lg={13}>
            <Row className='title'>{pageDetail?.title}</Row>
            <Row style={{margin: '0 20px'}}>
              <RichTextEditor
                className='editor-content'
                value={contentValue || RichTextEditor.createEmptyValue()}
                readOnly
                onChange={setContent}
              />
              <Link className='show-detail' to='/about/gioi-thieu-rentzy'>
                Xem chi tiết
                <span className='icon'>
                  <ArrowRight />
                </span>
              </Link>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ShortIntroduce;
