import React from 'react';
import {Row} from 'antd';
import {Link} from 'gatsby';

import {buildImageUrl} from '~utils/helper';

import './styles.scss';

const Occation = ({title, data, location}) => {
  const getLink = url => {
    if (url.indexOf('//') === 0 || url.indexOf('http') === 0) {
      return url;
    }

    const newUrl = url.includes('/') ? url : `/${url}`;

    return newUrl;
  };

  const getTarget = url => {
    if ((url.indexOf('//') === 0 || url.indexOf('http') === 0) && !url.includes(location?.hostname)) {
      return '_blank';
    }

    return '_self';
  };

  const getItem = item => {
    return (
      <Link key={item.id} className='item' to={getLink(item.url)} target={getTarget(item.url)}>
        <div className='icon'>
          <div
            className='d-icon'
            style={{
              backgroundImage: `url(${buildImageUrl(item.imageUrl, '_x56')})`,
            }}
          />
        </div>
        <div className='item-title'>{item.name}</div>
      </Link>
    );
  };

  return (
    <div className='occasion'>
      <div className='title'>{title}</div>
      <Row justify='center'>{(data || []).map(x => getItem(x))}</Row>
    </div>
  );
};
export default Occation;
