// extracted by mini-css-extract-plugin
export var buttonGroup = "styles-module--buttonGroup--7iTbK";
export var container = "styles-module--container--lE-OM";
export var description = "styles-module--description---HR-i";
export var imageWrap = "styles-module--imageWrap--e8AJl";
export var polygon = "styles-module--polygon--cu5eL";
export var primaryButton = "styles-module--primaryButton--p7kzm";
export var secondaryButton = "styles-module--secondaryButton---wOcO";
export var slogan = "styles-module--slogan--JFHV7";
export var title = "styles-module--title--2Ah2K";
export var utils = "styles-module--utils--CVMKE";
export var wrapper = "styles-module--wrapper--VoNAM";