import React from 'react';
import {Col, Row, Skeleton} from 'antd';
import _get from 'lodash/get';

import Utilities from '~components/home-utitlities';
import SwiperBanner from './swiper';
import {SwiperSlide} from 'swiper/react';
import BannerItem from './banner';
import useFetch from '../../hooks/useFetch';
import {buildImageUrl} from '~utils/helper';

import * as styles from './styles.module.css';

export default function CTABanners() {
  const {status, data} = useFetch(`${process.env.GATSBY_API_URL}/v1/consumer-banner-setting/list`);

  const isFetching = status === 'fetching';
  if (isFetching) {
    return <Skeleton />;
  }
  if (!data && !data.items) {
    return null;
  }

  const banners = _get(data, ['data'], []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Row justify='center' align='middle'>
          <Col sm={23} lg={23} xl={21}>
            <SwiperBanner>
              {banners?.map(({id, title, content, imageUrl: image, primaryButton, secondaryButton, description}) => (
                <SwiperSlide key={id}>
                  <BannerItem
                    title={title}
                    subtitle={content?.contentText}
                    subtitleLink={content?.textLink}
                    subtitleUrl={content?.linkUrl}
                    image={buildImageUrl(image, '800x1200')}
                    previewImage={buildImageUrl(image, '80x120')}
                    description={description}
                    primaryButton={primaryButton?.displayName}
                    primaryButtonLink={primaryButton?.linkUrl}
                    secondaryButton={secondaryButton?.displayName}
                    secondaryButtonLink={secondaryButton?.linkUrl}
                  />
                </SwiperSlide>
              ))}
            </SwiperBanner>
          </Col>
        </Row>
      </div>
      <Row justify='center'>
        <Col sm={23} lg={23} xl={21}>
          <Utilities />
        </Col>
      </Row>
    </div>
  );
}
