import React from 'react';
import {Link} from 'gatsby';
import {Col, Row, Image} from 'antd';

import * as styles from './styles.module.css';

export default function Item({
  title,
  subtitle,
  subtitleLink,
  subtitleUrl,
  image,
  previewImage,
  description,
  primaryButton,
  primaryButtonLink,
  secondaryButton,
  secondaryButtonLink,
}) {
  return (
    <Row justify='space-between' align='middle'>
      <Col
        md={{
          span: 24,
          order: 2,
        }}
        xl={{
          span: 12,
          order: 1,
        }}
      >
        <div className={styles.banner}>
          <div>
            {title && <h2 className={styles.title}>{title}</h2>}
            <h1 className={styles.slogan}>
              {subtitle} <Link to={subtitleUrl}>{subtitleLink}</Link>
            </h1>
            {description && <h5 className={styles.description}>{description}</h5>}
          </div>
          <div className={styles.buttonGroup}>
            {primaryButton && (
              <Link className={styles.primaryButton} to={primaryButtonLink}>
                {primaryButton}
              </Link>
            )}
            {secondaryButton && (
              <Link className={styles.secondaryButton} to={secondaryButtonLink} type='primary'>
                {secondaryButton}
              </Link>
            )}
          </div>
        </div>
      </Col>
      <Col
        md={{
          span: 24,
          order: 1,
        }}
        xl={{
          span: 12,
          order: 2,
        }}
      >
        <div className={styles.imageWrap}>
          <Image
            className={styles.polygon}
            placeholder={<Image width={550} height={600} preview={false} src={previewImage} />}
            width={550}
            height={600}
            preview={false}
            alt={subtitle}
            src={image}
          />
        </div>
      </Col>
    </Row>
  );
}
