import React from 'react';
import {Col, Divider, Row} from 'antd';
import {LazyLoadComponent, trackWindowScroll} from 'react-lazy-load-image-component';
import Collection from '~components/collection';
import {BannerTypes, CollectionTypes} from '~utils/enum';
import SmallBanner from '~components/small-banner';
import ShortIntroduce from './short-introduce';
import Occation from './occation';
import CTABanners from '~components/cta-banners';

import './styles.scss';

const Home = ({location, scrollPosition, banners}) => {
  const smallBanners = banners.filter(x => x.type === BannerTypes.Small) || [];

  return (
    <div id='home'>
      <CTABanners />
      <LazyLoadComponent height={500} width={500} scrollPosition={scrollPosition}>
        <div className='com-occasion'>
          <Row justify='center'>
            <Col lg={24} xl={22}>
              <Occation
                title='Mặc Vào Dịp...'
                data={banners?.filter(x => x.type === BannerTypes.Occasion) || []}
                location={location}
              />
            </Col>
          </Row>
        </div>
      </LazyLoadComponent>
      <Divider style={{margin: '40px 0'}} />
      <LazyLoadComponent height={500} width={500} scrollPosition={scrollPosition}>
        <div className='com-occasion'>
          <Row justify='center'>
            <Col lg={24} xl={22}>
              <Occation
                title='Mặc Theo Phong Cách...'
                data={banners?.filter(x => x.type === BannerTypes.Style) || []}
                location={location}
              />
            </Col>
          </Row>
        </div>
      </LazyLoadComponent>
      <LazyLoadComponent height={500} width={500} scrollPosition={scrollPosition}>
        <div className='com-trending'>
          <Row justify='center'>
            <Col lg={24} xl={22}>
              <Collection
                item={{title: 'Xu Hướng Thịnh Hành', link: '/trending?sort=top_seller'}}
                type={CollectionTypes.Trending}
              />
            </Col>
          </Row>
        </div>
      </LazyLoadComponent>
      <LazyLoadComponent height={500} width={500} scrollPosition={scrollPosition}>
        <div className='com-new-arrival'>
          <Row justify='center'>
            <Col lg={24} xl={22}>
              <Collection
                item={{title: 'Hàng Mới Về', link: '/new-arrival?sort=newest'}}
                type={CollectionTypes.NewArrival}
              />
            </Col>
          </Row>
        </div>
      </LazyLoadComponent>
      <LazyLoadComponent height={500} width={500} scrollPosition={scrollPosition}>
        <div className='com-product-collection'>
          <Row style={{marginBottom: '20px'}} justify='center'>
            <Col lg={24} xl={22}>
              <Collection
                item={{title: 'Sản Phẩm Khuyến Mãi', link: '/product-promotion?sort=top_seller'}}
                type={CollectionTypes.Sale}
              />
            </Col>
          </Row>
          {smallBanners && smallBanners.length > 0 && (
            <Row justify='center'>
              <Col lg={24} xl={22}>
                <SmallBanner banners={smallBanners} />
              </Col>
            </Row>
          )}
        </div>
      </LazyLoadComponent>
      <LazyLoadComponent height={500} width={500} scrollPosition={scrollPosition}>
        <div className='com-instroduce'>
          <Row justify='center'>
            <Col lg={24} xl={22}>
              <ShortIntroduce />
            </Col>
          </Row>
        </div>
      </LazyLoadComponent>
    </div>
  );
};

export default trackWindowScroll(Home);
